import Vue from 'vue'

export const External = new Vue({
    data: {
        csrf: window.csrf,
        url: {
            base: window.BASE_URL + '/',
            blob: window.BASE_BLOB_URL + '/',
            profiles: window.BASE_PROFILES_URL + '/',
            static: window.STATIC_URL + '/',
        },
        firebase_config: window.firebase_config,
        data: window.external_data,
    }
});
