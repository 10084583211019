<template>
  <div id="loader" class="loader">
    <img src="@/assets/images/Spinner.gif" alt="">
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>
.loader img {
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  top: 50%;
  left: 0;
  right: 0;
  margin: -20px auto 0;
}
</style>
