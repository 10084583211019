import Vue from 'vue'

export const Protocol = new Vue({
    methods: {
        map(type, data) {

            if ([
                    'input',
                    'textarea',
                    'checkbox',
                    'combobox',
                    'list',
                ].indexOf(type) < 0) {
                return {};
            }

            data = data || {};
            const rules = (data.rules || {});
            let result = {
                disabled: rules.disabled || false,
            };

            if (type === 'input' ||
                type === 'textarea') {

                result.value = data.value;

                [
                    'min',
                    'max',
                    'max_length',
                ].forEach((key) => {
                    if (key in rules) {
                        result[key] = rules[key];
                    }
                });

            } else if (type === 'checkbox') {

                result.checked = data.value;

            } else if (type === 'combobox') {

                result.list = rules.range || [];

                result.active_item_index = result.list
                    .findIndex(function(item) {
                        if ('value' in data) {
                            return item.value === data.value;
                        }
                        return false;
                    });

            } else if (type === 'list') {

                result = (rules.range || [])
                    .map((item) => {
                        return item.value || item.name || '';
                    });

            }

            return result;
        },
    }
});