<template>
  <button
    :class="[type, position]"
    :data-only-icon=" ! value"
    :style="style"
    @click.prevent="action"
  >
    <span
      :icon="icon"
    >{{ value }}</span>
  </button>
</template>

<script>
    export default {
        name: 'Button',
        props: {
            icon: {
                type: String,
                required: false,
                default: '',
            },
            value: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: false,
                default: 'green', // types: 'green', 'lightgreen', 'grayblue', 'purple', 'white', 'red', 'yellow' (:DD)
            },
            width: {
                type: Number,
                required: false,
                default: 0,
            },
            height: {
                type: Number,
                required: false,
                default: 40,
            },
            position: {
              type: String,
              required: false,
              default: 'center',
            }
        },
        data() {
            return {

            }
        },
        computed: {
            style() {
                function calcFontSize(height) {
                    let fsize = Math.floor(height * .4);
                    if (fsize < 10) {
                        return 10;
                    }
                    return fsize;
                }
                return {
                    minWidth: this.width + 'px',
                    height: this.height + 'px',
                    lineHeight: this.height - 2 * (this.type === 'grayblue' ? 2 : 1) + 'px',
                    fontSize: calcFontSize(this.height) + 'px',
                };
            },
        },
        methods: {
            action() {
                this.$emit('click');
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button {
        display: inline-block;
        max-width: 100%;
        padding: 0 10px;
        border: 1px solid transparent;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: #40bd5e;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: #fff;
        text-align: center;
        user-select: none;
        cursor: pointer;
        transition: border-color .2s,
                    color .2s,
                    background-color .2s;
    }
    button.center {
      margin: 0 auto;
    }
    button[data-only-icon] {
        padding: 0 10px;
    }
    .green {
      background-color: #009E03;
    }
    .lightgreen {
        border-color: #40bd5e;
        color: #40bd5e;
        background-color: #e4fbe9;
    }
    .grayblue {
        border: 2px solid #cedbea;
        font-weight: 500;
        color: #3cafff;
        background-color: #edf5fa;
    }
    .purple {
        background-color: #ad6bce;
    }
    .white {
      background-color: #fff;
      color: #40BD5E;
    }
    .transparent {
      background-color: #fff;
      color: #4A4A4A;
      border: 1px solid #D0D0D0;
    }
    .transparent:hover, .transparent:active {
      background-color: #edf5fa;
    }
    .red {
      background-color: #FF6B6B;
      color: #FFFFFF;
    }
    .red:hover, .red:active {
      background-color: #ee5e5e;
    }
    .yellow {
      background-color: #FFBE00;
    }
    .yellow:hover, .yellow:active {
      background-color: #FFBE00;
    }
    .white:hover {
      background-color: #F7F7F7;
    }
    .white:active {
      background-color: #ECECEC;
    }
    button:hover,
    .lightgreen:hover {
        background-color: #29b344;
    }
    .lightgreen:hover {
        border-color: #29b344;
        color: #fff;
    }
    .purple:hover {
        background-color: #c07ce1;
    }
    button:active,
    .lightgreen:active {
        background-color: #23993a;
    }
    .lightgreen:active {
        border-color: #23993a;
        color: #fff;
    }
    .grayblue:hover,
    .grayblue:active {
        border-color: #bfddff;
        color: #3494d9;
        background-color: #d9f0ff;
    }
    .purple:active {
        background-color: #9b5bbb;
    }

    button > span {
        display: inline-block;
        line-height: 1;
    }
    button > span[icon]:before {
        content: '';
        display: inline-block;
        height: 20px;
        width: 25px;
        margin-right: 8px;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: top;
    }
    button[data-only-icon] > span[icon]:before {
        margin: 0;
    }
    button > span[icon=""]:before {
        display: none;
    }
    button > span[icon="folder"]:before {
        background-image: url(~@/assets/images/button/icon_folder.svg);
    }
</style>
