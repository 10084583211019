import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

import {PhrasesAJAX} from '@/phrases/ajax'

/**
 * Todo: Remove. This. Completely.
 * Axios request
 *
 * @param {Object} data - The object.
 *  data: {
 *      @param {string} type    - URL key for request
 *      @param {string} method  - (optional) ('POST' by default) Request method
 *      @param {object} params  - (optional) Request params
 *      @param {function} then  - (optional) Handle success, where 'response.data' is argument
 *      @param {function} catch - (optional) Handle error, where 'error.response.data' is argument
 *  }
 */
export default function AJAX(data) {
    const config = Object.assign({
        store: {
            state: {
                toast: null,
            }
        },
        external: {
            csrf: null,
        },
        helper: {
            openURL: () => {},
            getPhraseByKey: () => {},
        },

        base_url: '',
        urls: {},
    }, this.$api.config);

    data = data || {};

    let url = config.urls[data.type] || '';
    if ( ! url) {
        console.error('"type" not found on url object!');
        return;
    }

    let params = data.params || {};
    params.csrf = config.external.csrf || '';

    const options = {
        method: (data.method || '').toUpperCase() || 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        baseURL: config.base_url,
        withCredentials: true,
        url: url,
        data: params,
    };

    Vue.axios(options)
        .then(function (response) {
            if (typeof data.then === 'function') {
                data.then(response.data);
            }
        })
        .catch(function (error) {
            let response = error.response || {};

            if (response.status === 422) {
                if (typeof data.catch === 'function') {
                    data.catch(response.data);
                }
                return;
            }

            console.group('XHR_error');
            console.info('URL:', (options.baseURL + options.url));
            console.error('Request Failed:', error.config);
            if (response.status) {
                console.warn('Status:',  response.status);
                console.warn('Headers:', response.headers);
                console.warn('Data:',    response.data);
            } else if (error.message) {
                console.warn('Error Message:', error.message);
            } else {
                console.warn('Unknown Error!');
            }
            console.groupEnd('XHR_error');

            if (response.status === 400 ||
                response.status === 401) {
                config.helper.openURL(((response || {}).data || {}).redirect || '');
                return;
            }

            if (response.status === 404) {
                if (config.store.state.toast) {
                    config.store.dispatch('openToast', {
                        type: 'error',
                        text: config.helper.getPhraseByKey(PhrasesAJAX.errors, 'not_found'),
                        delay: 5,
                    });
                }
                return;
            }

            if (config.store.state.toast) {
                config.store.dispatch('openToast', {
                    type: 'error',
                    text: config.helper.getPhraseByKey(PhrasesAJAX.errors, 'server_busy'),
                    delay: 5,
                });
            }
        });
}
