import Vue from 'vue'

export const Helper = new Vue({
    data: {
        regexps: {
            integer: /^[0-9]+$/i,
            email: /^[a-z0-9_.-]+[@]([a-z0-9-]+[.])+[a-z]{2,4}$/i,
        },
    },
    methods: {
        getEasingFunction(type) {
            const list = {
                // no easing, no acceleration
                linear: t => t,
                // accelerating from zero velocity
                easeInQuad: t => t*t,
                // decelerating to zero velocity
                easeOutQuad: t => t*(2-t),
                // acceleration until halfway, then deceleration
                easeInOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
                // accelerating from zero velocity
                easeInCubic: t => t*t*t,
                // decelerating to zero velocity
                easeOutCubic: t => (--t)*t*t+1,
                // acceleration until halfway, then deceleration
                easeInOutCubic: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
                // accelerating from zero velocity
                easeInQuart: t => t*t*t*t,
                // decelerating to zero velocity
                easeOutQuart: t => 1-(--t)*t*t*t,
                // acceleration until halfway, then deceleration
                easeInOutQuart: t => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
                // accelerating from zero velocity
                easeInQuint: t => t*t*t*t*t,
                // decelerating to zero velocity
                easeOutQuint: t => 1+(--t)*t*t*t*t,
                // acceleration until halfway, then deceleration
                easeInOutQuint: t => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
            };
            return list[type] || list['linear'];
        },

        getImageURL(id, type) {
            const types = {
                flair: 'dpi128/',
            };
            if (id) {
                return this.$external.url.blob +
                    (types[type] || '') +
                    id;
            }
            return '';
        },

        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        },

        openURL(url, target) {
            target = (function() {
                if ([
                        '_blank',
                        '_self',
                        '_parent',
                        '_top',
                    ].indexOf(target) < 0) {
                    return '_self';
                }
            })();

            let tab = window.open(url, target);

            if (target === '_blank') {
                tab.focus();
            }
        },

        decode(str) {
            return decodeURIComponent(str).replace(/\\x22|%22/g, '"');

            // let empty_textarea = document.createElement('textarea');
            // empty_textarea.innerHTML = this;
            // return empty_textarea.value;
        },

        encode(str) {
            return (str || '').replace(/[&<>"']/g, function ($0) {
                return '&' + {
                    '&': 'amp'
                    , '<': 'lt'
                    , '>': 'gt'
                    , '"': 'quot'
                    , "'": '#39'
                }[$0] + ';';
            });
        },

        getAvatarNickname(nickname) {
            if (!nickname) {
                return '';
            }

            if (nickname.match(/[a-zA-Z]/g)) {
                nickname = nickname.match(/[a-zA-Z]/g).join('');
            }

            nickname = nickname.slice(0, 2);
            nickname = nickname.charAt(0).toUpperCase() + nickname.charAt(1).toLowerCase();

            return nickname || '';
        },

        intBGRtoHexRGB(number) {
            if (number < 0) {
                number = 0xFFFFFFFF + number + 1;
            }
            number = parseInt(number, 10).toString(16).match(/.{1,2}/g);
            return '#' + number[2] + number[1] + number[0];
        },

        getAlevelImageSrc(str, type) {
            return str && str > 0 ? (this.$external.url.static + '/images/alevel/' + type + '/' + str + '.svg') : '';
        },

        getAlevelIconSrc(str) {
            return str ? (this.$external.url.base + '/images/alevel/icon_' + str + '.svg') : '';
        },

        getProfileUrl(nickname) {
          return this.$external.url.profiles + this.encode(this.decode(nickname));
        },

        getStoreUrl(seek) {
            return this.$external.url.base + 'store/' + (seek ? `?seek=${encodeURIComponent(seek)}` : '')
        },

        getStoreGiftsUrl(seek) {
            return this.getStoreUrl(seek) + '#gifts';
        },

        validateByRegexp(regexp_name, str) {
            if ( ! this.regexps[regexp_name]) {
                return true;
            }
            return (this.regexps[regexp_name].test(str));
        },

        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },

        commaString: function(str) {
            return (str * 1).toLocaleString('en-US');
        },

        replaceAll(str, reg_exp, new_string) {
            return str.replace(new RegExp(reg_exp, 'g'), function() { return new_string; });
        },

        getPhraseByKey(errors, error_key) {
            errors = errors || {};

            if ( ! error_key ||
                typeof error_key !== 'string') {
                return '';
            }

            return errors[error_key] || this.replaceAll(this.capitalize(error_key), '_', ' ');
        },
    }
});
