import Vue from 'vue'

export const CONSTANTS = new Vue({
    data: {
        subscriptions: {
            room: {
                '0': {
                    name: 'free',
                    backgroundColor: '',
                },
                '10': {
                    name: 'green',
                    backgroundColor: '#40bd5e',
                },
                '20': {
                    name: 'purple',
                    backgroundColor: '#ad6bce',
                },
                '30': {
                    name: 'gold',
                    backgroundColor: '#ffbe00',
                },
                '40': {
                    name: 'platinum',
                    backgroundColor: '#8aadb5',
                },
                '50': {
                    name: 'diamond',
                    backgroundColor: '#6dc7ee',
                },
                '60': {
                    name: 'vip',
                    backgroundColor: '#ec5377',
                },
                '70': {
                    name: 'plus',
                    backgroundColor: '#6dc7ee',
                },
            },
        },
    },

    methods: {
        getRoomSubscriptionParam(id, param) {
            return (this.subscriptions.room[id.toString()] || this.subscriptions.room['0'])[param] || '';
        },
        isRoomSubscription(id, name) {
            return this.getRoomSubscriptionParam(id, 'name') === name;
        },
    },
});
