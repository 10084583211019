<template>
  <div id="toast"
       :class="[{ show: toastOpened }, toastType ]"
  >
    <div id="toast-content">
      <div id="toast-text">
        <span v-html="toastText" />
      </div>
      <div id="toast-buttons"
           :class="{ 'button-custom': toastButtonCustomShow, 'button-close': toastButtonCloseShow }"
      >
        <button id="toast-button-custom"
                @click="toastButtonCustomEvent"
        >
          {{ toastButtonCustomText }}
        </button>

        <button id="toast-button-close"
                @click="close"
        >
          <svg width="10"
               height="10"
               viewBox="0 0 10 10"
               xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="#fff" fill-rule="evenodd" d="M6.333 5L10 8.667 8.667 10 5 6.333 1.333 10 0 8.667 3.667 5 0 1.333 1.333 0 5 3.667 8.667 0 10 1.333 6.333 5z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Toast',
        components: {

        },
        props: {

        },
        data() {
            return {

            };
        },
        computed: {
            toastOpened() {
                return this.$store.getters.isOpenedToast;
            },
            toastType() {
                if ([
                    'error',
                    'warning',
                    'success',
                    'info',
                ].indexOf(this.$store.state.toast.data.type) > -1) {
                    return this.$store.state.toast.data.type;
                }
                return 'default';
            },
            toastText() {
                return this.$store.state.toast.data.text || '';
            },
            toastButtonCustomShow() {
                return (this.$store.state.toast.data.button_custom || {}).action &&
                    this.toastButtonCustomText;
            },
            toastButtonCustomEvent() {
                return (this.$store.state.toast.data.button_custom || {}).action || function() {};
            },
            toastButtonCustomText() {
                return (this.$store.state.toast.data.button_custom || {}).text;
            },
            toastButtonCloseShow() {
                return this.$store.state.toast.data.button_close || false;
            },
        },
        created() {
            this.$store.registerModule('toast', {
                state: {
                    _bus: [],
                    _opened: false,
                    data: {},
                },
                getters: {
                    isOpenedToast(state) {
                        return state._opened;
                    },
                },
                mutations: {
                    addToastToBus(state, data) {
                        state._bus.push(data);
                    },
                    getToastFromBus(state) {
                        if (state._bus.length) {
                            state.data = state._bus.shift();
                            state._opened = true;
                        }
                    },
                    updateToastParams(state, data) {
                        Object.keys(data)
                            .forEach((key) => {
                                if ( ! key.indexOf('_')) {
                                    return;
                                }
                                state.data[key] = data[key];
                            });
                    },
                    setToastOpened(state, opened) {
                        state._opened = !!opened;
                    },
                },
                actions: {
                    openToast(context, data) {
                        if (data) {
                            context.commit('addToastToBus', data);
                        }
                        if (context.state._opened) {
                            return;
                        } else {
                            context.commit('getToastFromBus');
                        }
                        // toast autoclosing
                        if (context.state._opened &&
                            context.state.data.delay &&
                            ! context.state.data.button_close) {
                            this.dispatch('closeToast', context.state.data.delay);
                        }
                    },
                    closeToast(context, delay) {
                        setTimeout(() => {
                            context.commit('setToastOpened', false);
                            setTimeout(() => {
                                context.dispatch('openToast');
                            }, .2 * 1000);
                        }, (delay || 0) * 1000);
                    },
                },
            });
        },
        methods: {
            close() {
                this.$store.dispatch('closeToast');
            },
        },
    }

    /*
    Toast call example

    this.$store.dispatch('openToast', {
        type: '',
        text: 'Toast empty',
        button_close: true,
        button_custom: {
            text: '!alert "A"!',
            action: () => { alert('A'); },
        },
        delay: 5,
    });

    this.$store.dispatch('openToast', {
        type: 'info',
        text: 'Toast info',
        delay: 1,
    });

    this.$store.dispatch('openToast', {
        type: 'success',
        text: 'Toast success',
        delay: 1,
    });

    this.$store.dispatch('openToast', {
        type: 'error',
        text: 'Toast error',
        delay: 1,
    });

    this.$store.dispatch('openToast', {
        type: 'warning',
        text: 'Toast warning',
        button_close: true,
        button_custom: {
            text: '!alert "B"!',
            action: () => { alert('B'); },
        },
        delay: 5,
    });

    */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #toast {
        position: fixed;
        min-height: 70px;
        width: 100%;
        top: -100%;
        left: 0;
        padding: 0 30px;
        box-sizing: border-box;
        font-size: 0;
        color: #4d4d4d;
        text-align: center;
        line-height: 70px;
        white-space: nowrap;
        z-index: 20;
        transition: top .2s;
    }
    #toast.show {
        top: 0;
    }
    #toast-content {
        display: table;
        margin: 0 auto;
    }
    #toast-text {
        display: table-cell;
        font-size: 16px;
        font-weight: 400;
        vertical-align: middle;
        white-space: normal;
    }
    #toast-text > span {
        display: inline-block;
        line-height: 22px;
        white-space: normal;
        vertical-align: middle;
        overflow: hidden;
        cursor: default;
    }
    #toast-text > span >>> a {
        color: #3d95cc;
        text-decoration: none;
        cursor: pointer;
        transition: .2s;
    }
    #toast-text > span >>> a:hover {
        color: #3cafff;
    }
    #toast-text > span >>> a:active {
        color: #0075c7;
    }
    #toast-buttons {
        display: table-cell;
        white-space: nowrap;
    }
    #toast-buttons > * {
        display: inline-block;
        border: 1px solid rgba(77, 77, 77, .5);
        border-radius: 4px;
        box-sizing: border-box;
        font-family: Roboto, sans-serif;
        font-size: 15px;
        color: inherit;
        background-color: transparent;
        vertical-align: middle;
        cursor: pointer;
        transition: .2s;
    }
    #toast-buttons > *:hover,
    #toast-buttons > *:active {
        color: #fff;
        border-color: transparent;
    }
    #toast-buttons > *:focus {
        outline: none;
    }
    #toast-button-custom {
        display: none;
        height: 30px;
        min-width: 90px;
        margin-left: 18px;
        padding: 0 20px;
        font-size: 15px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    #toast-buttons.button-custom > #toast-button-custom {
        display: inline-block;
    }
    #toast-button-close {
        display: none;
        height: 30px;
        width: 30px;
        margin-left: 18px;
        padding: 9px;
    }
    #toast-buttons.button-close > #toast-button-close {
        display: inline-block;
    }
    #toast-button-close > svg {
        vertical-align: top;
    }
    #toast-button-close > svg > path {
        fill: #4d4d4d;
        fill-rule: evenodd;
        transition: .2s;
    }
    #toast-button-close:hover > svg > path,
    #toast-button-close:active > svg > path {
        fill: #fff;
    }

    #toast.default {
        color: #fff;
        background-color: rgba(0, 0, 0, .65);
    }
    #toast.default #toast-buttons > * {
        border-color: rgba(255, 255, 255, .5);
    }
    #toast.default #toast-buttons > *:hover,
    #toast.default #toast-buttons > *:active {
        border-color: transparent;
        color: #4d4d4d;
        background-color: #ccc;
    }
    #toast.default #toast-buttons > *:active {
        background-color: #a9a9a9;
    }
    #toast.default #toast-button-close > svg > path {
        fill: #fff;
    }
    #toast.default #toast-button-close:hover > svg > path,
    #toast.default #toast-button-close:active > svg > path {
        fill: #4d4d4d;
    }
    #toast.error {
        background-color: #ffd9d9;
    }
    #toast.error #toast-buttons > *:hover {
        background-color: #ff7777;
    }
    #toast.error #toast-buttons > *:active {
        background-color: #ee5e5e;
    }
    #toast.warning {
        background-color: #ffefc5;
    }
    #toast.warning #toast-buttons > *:hover {
        background-color: #ffbe00;
    }
    #toast.warning #toast-buttons > *:active {
        background-color: #e9ad00;
    }
    #toast.success {
        background-color: #8ceb95;
    }
    #toast.success #toast-buttons > *:hover {
        background-color: #53ce70;
    }
    #toast.success #toast-buttons > *:active {
        background-color: #30a14b;
    }
    #toast.info {
        background-color: #d9ecff;
    }
    #toast.info #toast-buttons > *:hover {
        background-color: #80c0eb;
    }
    #toast.info #toast-buttons > *:active {
        background-color: #58ace5;
    }
</style>
